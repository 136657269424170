/* eslint-disable complexity */
import React from 'react';
import { shape } from 'prop-types';
import { Row, Col, Card, Button } from 'antd';
import { isEmpty } from 'lodash';
import { Share } from 'arcl';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faShareAlt } from '@fortawesome/pro-light-svg-icons';
import { INVITE_ONLY_EVENT } from 'Src/adminEvents/constants';
import { PRIVACY_PUBLIC } from 'Src/alumniEvents/constants';
import I18nCustomFormatter from 'Src/common/components/i18nCustomFormatter';
import { MEDIUM_BREAKPOINT, MAX_CONTAINER_WIDTH } from '../../../../common/constants';

import DetailTabs from './DetailTabs';
import VenueCard from './VenueCard';
import OnlineVenueCard from './OnlineVenueCard';
import ContactDetailCard from './ContactDetailCard';
import GalleryCard from './GalleryCard';
import EventFeed from './EventFeed';

import './style.scss';

const vW = Math.min(document.documentElement.clientWidth, MAX_CONTAINER_WIDTH);

function DetailBox({ eventData }) {
  const eventImages = eventData.images.filter((image) => image.image_type === 'gallery_image');
  const isDiscussionsEnabled = eventData.is_discussions_enabled;

  return (
    <div id="event-detail-box">
      <Row gutter={32}>
        <Col xs={24} sm={24} md={14} lg={15} xl={15}>
          <DetailTabs eventData={eventData} />
          <If condition={vW >= MEDIUM_BREAKPOINT && isDiscussionsEnabled}>
            <EventFeed eventId={eventData.id} />
          </If>
        </Col>
        <Col xs={24} sm={24} md={10} lg={9} xl={9}>
          <If
            condition={
              eventData.type === INVITE_ONLY_EVENT && !window.guestData && window.registrationData.rsvp_status !== 'yes'
            }>
            <div className="detail-card">
              <div className="arc-H200 arc-color-gray10 mb8">You need a invitation to register</div>
              <div className="arc-color-gray7 mb32">
                This is a private event that requires an invitation to join, please check your email inbox to register
              </div>
            </div>
          </If>
          <div id="event-detail-cards-wrapper">
            <Choose>
              <When condition={!isEmpty(eventData.venue_link) && eventData.venue_privacy === PRIVACY_PUBLIC}>
                <div className="mb16">
                  <OnlineVenueCard venue={eventData.venue_link} />
                </div>
              </When>
              <Otherwise>
                <If condition={eventData.venue && eventData.venue_privacy === PRIVACY_PUBLIC}>
                  <div className="mb16">
                    <VenueCard venueData={eventData.venue} />
                  </div>
                </If>
              </Otherwise>
            </Choose>
            <If condition={eventData.contact_persons && eventData.contact_persons.length}>
              <ContactDetailCard contacts={eventData.contact_persons} />
            </If>
            <If condition={eventImages && eventImages.length}>
              <GalleryCard images={eventImages} eventName={eventData.name} />
            </If>
            <If condition={eventData?.is_share_enabled}>
              <Card bordered={false} className="event-card">
                <h3 className="arc-H200 arc-color-B85">
                  <I18nCustomFormatter id="event-share-card-title" />
                </h3>
                <p className="arc-p arc-color-B55">
                  Know someone who might be interested in attending? Let them know by sharing this event.
                </p>
                <div className="share-card-actions mt16">
                  <Share
                    title="Share"
                    url={`${window.location.origin}${window.location.pathname}`}
                    trigger="click"
                    disableNativeShare>
                    <Button type="link" className="share-card-icon-text link-btn arc-focus-outline">
                      <FontAwesomeIcon icon={faShareAlt} className="mr12" />
                      <p id="share-trigger" className="arc-anchor">
                        <span>Share</span>
                      </p>
                    </Button>
                  </Share>
                </div>
              </Card>
            </If>
          </div>
        </Col>
        <If condition={vW < MEDIUM_BREAKPOINT && isDiscussionsEnabled}>
          <Col xs={24} sm={24} md={14} lg={15} xl={15}>
            <EventFeed eventId={eventData.id} />
          </Col>
        </If>
      </Row>
    </div>
  );
}

DetailBox.propTypes = {
  eventData: shape(),
};

DetailBox.defaultProps = {
  eventData: {},
};

export default DetailBox;
