/* eslint-disable */
/* eslint-disable no-mixed-operators */
import React from 'react';
import { connect } from 'react-redux';
import { number, bool } from 'prop-types';
import { REDUX_STATUSES, BREAKPOINT } from '../../common/constants';
import { COVER_IMAGE, ORIENTATION, LOGO_IMAGE } from '../constants';
import ThemeXContainer from 'Src/common/components/themeXContainer';
import CartFooter from './common/cartFooter';

const EventXLayout = (props) => {
  const { heroBanner, status, eventImages, eventName, hideHeader, evenData } = props;
  const width = document.documentElement.clientWidth || 0;

  function getFeaturedImage() {
    const orientation = width < BREAKPOINT ? ORIENTATION.PORTRAIT : ORIENTATION.LANDSCAPE;
    const featuredImage = eventImages.filter(
      (image) => image.image_type === COVER_IMAGE && image.image_orientation === orientation,
    );
    if (featuredImage.length) {
      return featuredImage[0].image;
    }
    if (eventImages.length) {
      return eventImages[0].image;
    }

    return window.defaultBannerImage;
  }

  const featuredImage = status === REDUX_STATUSES.FETCHED ? getFeaturedImage() : window.preloadImage;
  return (
    <ThemeXContainer
      showHeroBanner
      disableLogoRedirect={evenData?.is_detach_from_engagement_platform}
      isLoaded={status === REDUX_STATUSES.FETCHED}
      heroBannerImage={featuredImage}
      heroTitle={(heroBanner && heroBanner.title) || eventName}
      showHeroBackLink={!heroBanner.hideHeroBackLink && !hideHeader}
      heroBackText={heroBanner && heroBanner.back_text}
      redirectLink={!hideHeader && heroBanner.redirect_link}
      showHeader={!hideHeader}
      userData={window.userData}
      showLoginButton={!evenData?.is_detach_from_engagement_platform}>
      {props.children}
      <If condition={props.showFooter}>
        <CartFooter currentStep={props.currentStep} requiredFormValidation={props.requiredFormValidation} />
      </If>
    </ThemeXContainer>
  );
};

EventXLayout.propTypes = {
  showFooter: bool.isRequired,
  requiredFormValidation: bool.isRequired,
  currentStep: number.isRequired,
};

const mapStateToProps = (state) => ({
  eventName: (state.eventDataReducer.data && state.eventDataReducer.data.name) || '',
  evenData: state.eventDataReducer.data && state.eventDataReducer.data,
  eventImages:
    (state.eventDataReducer.data &&
      state.eventDataReducer.data.images.filter((image) => image.image_type !== LOGO_IMAGE)) ||
    [],
  hideHeader: !state.eventDataReducer.data,
  status: state.eventDataReducer.status,
  heroBanner: state.heroBannerDataReducer.data || {},
});

export default connect(mapStateToProps, null)(EventXLayout);
