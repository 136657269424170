import React, { Component } from 'react';
import { number } from 'prop-types';

import I18nCustomFormatter from 'Src/common/components/i18nCustomFormatter';
import { MEDIUM_BREAKPOINT, MAX_CONTAINER_WIDTH } from '../../../../../common/constants';
import { getFeedUserToken } from '../../../../../common/actions';
import Feed from '../../../../../common/components/themexFeeds/feed';
import ErrorBoundary from '../../../../../common/components/errorBoundary';
import './style.scss';

const { userData } = window;
const width = Math.min(document.documentElement.clientWidth, MAX_CONTAINER_WIDTH);

class EventFeed extends Component {
  constructor(props) {
    super(props);
    this.state = {
      token: null,
      loading: true,
    };
  }

  componentDidMount() {
    getFeedUserToken(window.userId).then((response) => {
      this.setState({
        loading: false,
        token: response.data.token,
      });
    });
    // if (width > MEDIUM_BREAKPOINT) {
    //     this.stickSidebar();
    // }
  }

  stickSidebar() {
    const element = document.getElementById('event-detail-cards-wrapper');
    const elementWidth = element.clientWidth;
    if (elementWidth > 0) {
      const scrollWhen = element.getBoundingClientRect().y;
      window.addEventListener(
        'scroll',
        function (event) {
          const scrollTop = this.scrollY;
          let position = 'relative';
          let top = 0;
          let width = 'auto';
          if (scrollTop > scrollWhen) {
            position = 'fixed';
            width = `${elementWidth}px`;
            top = '20px';
          }
          element.style.position = position;
          element.style.top = top;
          element.style.width = width;
        },
        false,
      );
    } else {
      window.setTimeout(() => {
        this.stickSidebar();
      }, 1000);
    }
  }

  render() {
    return (
      <div id="event-feed-wrapper">
        <h2 className="arc-H400" id="event-feed-title">
          <I18nCustomFormatter id="event-feed-title" />
        </h2>
        <Choose>
          <When condition={this.state.loading} />
          <Otherwise>
            <ErrorBoundary>
              <Feed
                token={this.state.token}
                feedGroup={window.streamFeedGroup}
                moduleName={window.moduleName}
                objectId={this.props.eventId}
                feedType={window.feedType}
                user={userData}
                isAdmin={window.isSiteAdmin}
                isLoggedIn={!!userData}
                feedUserId={window.streamId}
              />
            </ErrorBoundary>
          </Otherwise>
        </Choose>
      </div>
    );
  }
}

Feed.propTypes = {
  eventId: number,
};

export default EventFeed;
