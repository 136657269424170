import React from 'react';
import { bool, func } from 'prop-types';

import NonLoggedInPermissionState from './nonLoggedInPermissionState';
import NoContentState from './noContentState';

function FeedPermissionAndEmptyState({ isNonLoggedIn, hasNoContent, handleNonLoggedIn }) {


    return (
        <Choose>
            <When condition={isNonLoggedIn}>
                <NonLoggedInPermissionState handleNonLoggedIn={handleNonLoggedIn} />
            </When>
            <When condition={hasNoContent}>
                <NoContentState />
            </When>
        </Choose>
    )
}

FeedPermissionAndEmptyState.propTypes = {
    isNonLoggedIn: bool,
    hasNoContent: bool,
    handleNonLoggedIn: func
}

FeedPermissionAndEmptyState.defaultProps = {
    isNonLoggedIn: false,
    hasNoContent: false,
}

export default FeedPermissionAndEmptyState;